@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;700&display=swap');


* th {
  font-family: 'Outfit ,Lato, sans-serif';
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #a3a2a2;
  border-radius: 3px;
}

.print-content {
  display: none;
}

.body {
  font-family: 'Outfit ,Lato, sans-serif';
}

/* styles.css */
.prescription-container {
  padding: 0 7rem;
  padding-top: 0.25rem;
  width: 1170px;
  min-height: 1560px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-img {
  position: relative;
  left: 0;
  top: 3rem;
}

.title {
  text-align: center;
  margin: 0.25rem 0 0.75rem;
  font-size: 32px;
}

.doctor-info,
.consultant-info {
  margin-right: 10px;
}

.patient-details {
  margin-bottom: 0.5rem;
}

.notes {
  margin-right: 6rem;
}

.footer {
  text-align: center;
  padding-top: 0.75rem;
  font-size: 12px;
}

.btn {
  /* background-color: blue; */
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
  color: #ccc;
  position: relative;
  border-radius: 20px;
  /* Rounded corners */
  transition: color 0.25s 0.083s;
}

.btn:focus {
  outline: 2px dotted #55d7dc;
}

.draw-border {
  box-shadow: inset 0 0 0 2px #ccc;
  border-radius: 20px;
  /* Rounded corners for the border */
}

.draw-border::before,
.draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 20px;
  /* Ensure pseudo-elements also have rounded corners */
}

.draw-border::before {
  top: 0;
  left: 0;
  border-top-width: 2px;
  border-right-width: 2px;
}

.draw-border::after {
  bottom: 0;
  right: 0;
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.draw-border:hover {
  color: #ffffff;
}

.draw-border:hover::before,
.draw-border:hover::after {
  /* border-color: #0c73b8; */
  transition: border-color 0s, width 0.25s, height 0.25s;
  height: 40px;
  width: 100%;
  height: 100%;
  /* box-shadow: rgb(204, 219, 232) 1px 1px 2px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 3px 1px inset;  glass effect */
}

.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.margin-row {
  margin-bottom: 10px;
}

/* //this is for date picker */


.FC {
  background-color: #FF8D1E !important;
  color: white !important;
}

.FC ._1g {
  background-color: #FF8D1E !important;
  color: #fff;
}

.jb {

  margin-bottom: 5px;
  font-family: 'Outfit';
  position: relative;
  font-weight: 400;
}


/* 
.Kq svg {
  fill: #6d6d6d;
  display: none; 
}  */


.Ez:after {
  /* display: none; */

  margin-left: -8px;
  /* background-color: transparent; */
  /* background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0.5), hsla(0deg, 0%, 100%, 0)); */
}

.WP:after {
  /* display: none; */
  /* left: px; */
  margin-right: -6px;
  /* background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0.5), hsla(0deg, 0%, 100%, 0)); */
}

/*  
.page {
  margin: auto;
  page-break-after: avoid; 
} */



.check:checked+.checktoggle {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.notification-list .status-toggle {
  float: right;
}


.check:checked+.checktoggles {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.check:checked+.checktoggles:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}

.checktoggles:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}

.checktoggles {
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0;
  height: 15px;
  margin-left: 10px;
  top: 3px;
  margin-bottom: 0;
  position: absolute;
  width: 30px;
  display: inline-block;
}


.check:checked+.checktoggle {
  background-color: #009efb;
  border: 1px solid #009efb;
}


.d-flex {
  display: flex !important;
}

/* Base Toggle Switch Styling */
.checktoggle {
  background-color: #ccc;
  /* Default gray color for OFF */
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  position: relative;
  width: 48px;
  transition: background-color 300ms ease;
}

/* Toggle Thumb (Switch Knob) */
.checktoggle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: left 300ms ease;
}

/* Toggle Active State (ON) */
.check:checked+.checktoggle {
  background-color: #009efb;
  /* Blue for ON state */
}

/* Move the knob to the right for Active state */
.check:checked+.checktoggle:after {
  left: 28px;
}

/* Hide the input checkbox */
.check {
  display: none;
}